<template>
  <div class="home">
    <div class="flex cloumn-top">
      <div class="item-1">
        <cloumn-item class="small-item" :title="`环境空气质量：`">
          <air-quality slot></air-quality>
        </cloumn-item>
        <cloumn-item class="small-item" :title="`商圈事故统计：`">
          <business-accident slot></business-accident>
        </cloumn-item>
        <cloumn-item class="small-item" :title="`事故处理情况：`">
          <incident slot></incident>
        </cloumn-item>
      </div>
      <div class="item-2">
        <cloumn-item class="big-item" :title="`实时监控：`">
          <div class="videos">
            <div class="video-1">
              <video
                :options="options"
                ref="videoPlayer1"
                class="video-js"
              >
              </video>
            </div>
            <div class="video-2">
              <video
                :options="options"
                ref="videoPlayer2"
                class="video-js"
              >
              </video>
            </div>
            <div class="video-3">
              <video
                :options="options"
                ref="videoPlayer3"
                class="video-js"
              >
              </video>
            </div>
            <div class="video-4">
              <video
                :options="options"
                ref="videoPlayer4"
                class="video-js"
              >
              </video>
            </div>
          </div>
        </cloumn-item>
      </div>
      <div class="item-3">
        <cloumn-item class="right-item" :title="`监控点位：`">
          <ul class="cameras">
            <li v-for="(camera, i) in cameras" :key="i" @click="playCameraStream(camera.site_code)">{{camera.site_name}}</li>
          </ul>
        </cloumn-item>
      </div>
    </div>
  </div>
</template>

<script>
import cloumnItem from "@/components/home/cloumnItem";
import airQuality from "@/components/home/airQuality";
import businessAccident from "@/components/home/businessAccident";
import incident from "@/components/home/incident";
import videojs from 'video.js'

import { getCameras, getCameraStreamURL } from "@/api/api.js";

export default {
  name: "Home",
  components: {
    cloumnItem,
    airQuality,
    businessAccident,
    incident,
  },
  data() {
    return {
      player1: null,
      player2: null,
      player3: null,
      player4: null,
      currentPlayerIndex: 1,
      options: {
        fluid: true,
        muted: true,
        // sources: [{
        //   type: "application/x-mpegURL",
        //   src: "http://kbs-dokdo.gscdn.com/dokdo_300/_definst_/dokdo_300.stream/playlist.m3u8",
        //   withCredentials: false
        // }]
      },
      cameras: []
    };
  },
  methods: {
    getData() {
      getCameras().then((res) => {
        this.cameras = res.data.data
        this.playCameraStream(this.cameras[0].site_code)
        this.playCameraStream(this.cameras[1].site_code)
        this.playCameraStream(this.cameras[2].site_code)
        this.playCameraStream(this.cameras[3].site_code)
      });
    },
    playCameraStream(site_code) {
      getCameraStreamURL(site_code).then((res) => {
        let player = this["player" + this.currentPlayerIndex]
        player.src({
          type: "application/x-mpegURL",
          src: res.data.data,
          withCredentials: false
        })
        player.play()
        this.currentPlayerIndex += 1
        if(this.currentPlayerIndex == 5) this.currentPlayerIndex = 1
      })
    }
  },
  mounted() {
    this.getData()
    this.player1 = videojs(this.$refs.videoPlayer1, this.options, function onPlayerReady() { })
    this.player2 = videojs(this.$refs.videoPlayer2, this.options, function onPlayerReady() { })
    this.player3 = videojs(this.$refs.videoPlayer3, this.options, function onPlayerReady() { })
    this.player4 = videojs(this.$refs.videoPlayer4, this.options, function onPlayerReady() { })
  },
  beforeDestroy() {
    if (this.player1) this.player1.dispose()
  }
}
</script>

<style scoped>
.small-item {
  width: 400px;
  height: 260px;
}
.big-item {
  margin-right: 30px;
}
.item-1:first-child,
.cloumn-bottom .small-item:first-child {
  margin-right: 30px;
}
.item-1 .small-item {
  margin-bottom: 30px;
}

.item-2{
  flex: 1;
}

.flex {
  align-items: start;
}
.right-item {
  width: 140px;
}

ul.cameras{
  height: 760px;
  overflow: auto;
}
.cameras::-webkit-scrollbar {
  width : 6px;
  height: 1px;
 }
.cameras::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #535353;
}
.cameras::-webkit-scrollbar-track {
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background   : #ededed;
  }
ul.cameras li{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
}
.video-js{
  margin-top: 20px;
}
.videos{
  width: 100%;
  height: 50%;
  display: flex;
  flex-wrap: wrap;
}

.video-1{
  flex: 1;
  margin-right: 10px;
}
.video-2{
  padding-left: 10px;
  min-width: 50%;
  max-width: 50%;
}
.video-3{
  padding-right: 10px;
  min-width: 50%;
  max-width: 50%;
}
.video-4{
  padding-left: 10px;
  min-width: 50%;
  max-width: 50%;
}
</style>
